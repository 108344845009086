import React from "react"

import Layout from "../../layouts/En"

import SEO from "../../components/SEO"

const ThankYouPage = () => (
  <Layout title="Thank you">
    <SEO keywords={[`thank`, `you`, `contact`]} title="Thank you" />

    <h3 className="text-center">
      We will get back to you as soon as possible.
    </h3>
  </Layout>
)

export default ThankYouPage
